import logo from './logo.svg';
import './App.css';
import 'antd/dist/antd.css';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import {
    Layout,
    Menu,
    Radio,
    TreeSelect,
    DatePicker,
    Switch,
    AutoComplete,
    Button,
    Cascader,
    Checkbox,
    Col,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    MenuProps,
    Table,
    Modal,
    Upload,
    message,
    Space
} from 'antd';

import AWS from 'aws-sdk'
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import store from 'store2';
import { apiRoot } from "./Config";


//https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/s3-example-creating-buckets.html
const credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: "us-east-1:d0488ef8-af13-4ba0-9c1c-aebd3215c422" });
const S3_BUCKET = 'newgen-ae-dev';
const REGION = 'us-east-1';

AWS.config.update({
    region: REGION,
    credentials: credentials
}
);

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})

AWS.config.credentials.refresh();

let headers = {
    "email":"saran@newgen.co",
    "token":"1YgC880MkyCDEY_rrG1vSi-Hqtff1d2ziLOVAZa9f7bs-12BwB-cvKLv_T4nSArZ37I3dFcqmOAYR_Z9IVekuFmRk"
}
if(window.location.host=="localhost:3000")
{
    headers = {
        "email":"saran@newgen.co",
        "token":"d453efc981e21274e2d222f9c8a3c83f-f564c243d8cb7d18c04c02adf6459132"
    }
}

const columns = [
    {
        key: "id",
        title: 'Name',
        dataIndex: 'id'
    },
    {
        key: "created_at",
        title: 'Created At',
        dataIndex: 'created_at'
    }
];


const { Header, Sider, Content } = Layout;
const { Option } = Select;

export default function Insurance(props) {
    const [form] = Form.useForm();
    const [collapsed, setCollapsed] = useState(false);
    const params = useParams();
    const [data, setData] = useState([]);
    const [employeeData, setEmployeeData] = useState({});
    const [newDependentForm, setNewDependentForm] = useState("none");




    useEffect(() => {
        console.log(params);

        if(params.page == "status")
        {
            var apiUrl = `https://pubkit.newgen.co/office/api/approvedqueries/fuzzyid/insurance-not-acknowledged`;
            axios.post(apiUrl, {"where":[],"limit":5}, { headers }).then(res => {
                for(var i=0; i<res.data.length; i++)
                {
                    res.data[i]["sl"] = (i+1);
                }
                setData(res.data);
            });
        }

        if(params.page == "details")
        {
            var apiUrl = `${apiRoot}office/api/insurance/` + params.fuzzyid;
            axios.get(apiUrl, { headers }).then(res => {
                console.log(res.data)
                var employeeData = {};
                employeeData["totalPremium"] = 0;
                employeeData["dependents"] = 0;
                employeeData["employeeShare"] = 0;
                employeeData["employerShare"] = 0;
                var employeeSharePresent = false;
                    
                for(var i=0; i<res.data.length; i++)
                {
                    
                    if(res.data[i]["status"]=="active")
                    {
                        res.data[i]["action"]="Remove";
                    }
                    else
                    {
                        res.data[i]["action"]="Add";
                    }
                    
                    if(res.data[i]["status"]=="removed") continue;
                    
                    employeeData["employeeId"] = res.data[i]["employeeId"];
                    employeeData["dependents"]++;
                    employeeData["totalPremium"] += res.data[i]["premium"];

                    if(res.data[i]["relation"]=="Employee/Self")
                    {
                        employeeData["name"] = res.data[i]["name"];
                        employeeData["employerShare"] += res.data[i]["premium"];
                    }
                    else
                    {
                        employeeData["employeeShare"] += res.data[i]["premium"];
                        employeeSharePresent = true;
                    }
                    employeeData["premium"] = res.data[i]["premium"];
                    
                }

                if(employeeSharePresent)
                {
                    employeeData["employerShare"] = employeeData["employerShare"] + 2812;
                    employeeData["employeeShare"] = employeeData["employeeShare"] - 2812;
                }

                setEmployeeData(employeeData);
                setData(res.data)
            });
        }


    }, []);


    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="86">+86</Option>
                <Option value="87">+87</Option>
            </Select>
        </Form.Item>
    );
    const suffixSelector = (
        <Form.Item name="suffix" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="USD">$</Option>
                <Option value="CNY">¥</Option>
            </Select>
        </Form.Item>
    );
    const [autoCompleteResult, setAutoCompleteResult] = useState([]);

    const onWebsiteChange = (value) => {
        if (!value) {
            setAutoCompleteResult([]);
        } else {
            setAutoCompleteResult(['.com', '.org', '.net'].map((domain) => `${value}${domain}`));
        }
    };

    const websiteOptions = autoCompleteResult.map((website) => ({
        label: website,
        value: website,
    }));

    const items1: MenuProps['items'] = ['1', '2', '3'].map(key => ({
        key,
        label: `nav ${key}`,
    }));


    const menuClicked = (e) => {
        console.log(e);
        window.location.href = '/freelancer/' + e.key;
    };




    return (
        <Layout>
            <Header
                className="site-layout-background"
                style={{
                    padding: 0,
                }}
            >
                <div className="logo" />
            </Header>
            <Layout className="site-layout">
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                    }}
                >

                    {(params.page == "details") && (renderDashboard())}
                    {(params.page == "agree") && (renderAgreedMessage())}
                    {(params.page == "status") && (renderWaitingForResponse())}

                </Content>
            </Layout>
        </Layout>
    );


    function renderWaitingForResponse()
    {
        return (<div>
            <h1>Waiting for response</h1>
            <Table rowKey="id" columns={
                [
                    {
                        key: "sl",
                        title: 'SL',
                        dataIndex: 'sl'
                    },
                    {
                        key: "employee_id",
                        title: 'Employee Id',
                        dataIndex: 'employee_id'
                    },
                    {
                        key: "name",
                        title: 'Name',
                        dataIndex: 'name'
                    },
                    {
                        key: "buhead",
                        title: 'BU Head',
                        dataIndex: 'buhead'
                    }
                ]
            } dataSource={data} pagination={false}/>
        </div>)
    }

    function renderDashboard() {
        const insuranceAction = (record) => {
            if((record["relation"]!="Employee/Self") && (record.status=="active"))
            {
                record.status = "removed";
            }
            else
            {
                record.status = "active";
            }
            console.log(record);
            axios.post(`${apiRoot}office/api/insurance/update/`+params.fuzzyid, record, { headers }).then(res => {
                console.log(res.data)
                window.location.href = window.location.href;
            });
        }

        const onFinishNewDependent = (values) => {
            console.log(values);
            values.dob = values.dob.format("YYYY-MM-DD")
            axios.post(`${apiRoot}office/api/insurance/add/`+params.fuzzyid, values, { headers }).then(res => {
                console.log(res.data)
                window.location.href = window.location.href;
            });
          };

          const employeeAgree = (e) => {
            axios.post(`${apiRoot}office/api/insurance/agree/`+params.fuzzyid, {}, { headers }).then(res => {
                message.success('Thank you for your acknowledgement. You will receive an email shortly with the details', 10);
            });
          };


          const detailChanges = (record, field, value) => {
            record[field] = value;
            axios.post(`${apiRoot}office/api/insurance/update/`+record.fuzzyId, record, { headers }).then(res => {
                message.success('Details are updated', 10);
            });
          };
          

          

        return (
            <div>
                
                <div style={{maxWidth:"780px"}}>
                    <h1>Premium Employee vs Employer share</h1>
                    <table className="table table-striped">
                        <tbody>
                            <tr>
                                <td>Employee Name</td>
                                <td>{employeeData.name}</td>
                            </tr>
                            <tr>
                                <td>Employee ID</td>
                                <td>{employeeData.employeeId}</td>
                            </tr>
                            <tr>
                                <td>Number of Insured Person(s)</td>
                                <td>{employeeData.dependents}</td>
                            </tr>
                            <tr>
                                <td>Premium per Person</td>
                                <td>{employeeData.premium}</td>
                            </tr>
                            <tr>
                                <td>Total Premium Cost</td>
                                <td>{employeeData.totalPremium}</td>
                            </tr>
                            <tr>
                                <td>Employer Share</td>
                                <td>{employeeData.employerShare}</td>
                            </tr>
                            <tr>
                                <td>Employee Share</td>
                                <td>{employeeData.employeeShare}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div style={{marginTop:"50px"}}>
                    <Space size={50}>
                        <Button type="primary" onClick={(e) => employeeAgree(e)}>I Agree to the details</Button>
                    </Space>
                </div>

                <div style={{marginTop:"50px"}}>
                <h1>Particulars of insurance premium</h1>
                <Table rowKey="id" columns={
                    [
                        {
                            key: "name",
                            title: 'Name',
                            dataIndex: 'name'
                        },
                        {
                            key: "relation",
                            title: 'Relation',
                            render: (record) => {
                                return (
                                    <Select onChange={(e) => detailChanges(record, "relation", e)} defaultValue={record.relation} style={{ width: 120 }} >
                                        <Select.Option value="Employee/Self">Employee/Self</Select.Option>
                                        <Select.Option value="Brother">Brother</Select.Option>
                                        <Select.Option value="Father">Father</Select.Option>
                                        <Select.Option value="Father-in-law">Father-in-law</Select.Option>
                                        <Select.Option value="Mother">Mother</Select.Option>
                                        <Select.Option value="Mother-in-law">Mother-in-law</Select.Option>
                                        <Select.Option value="Spouse">Spouse</Select.Option>
                                        <Select.Option value="Sister">Sister</Select.Option>
                                        <Select.Option value="Daughter">Daughter</Select.Option>
                                        <Select.Option value="Son">Son</Select.Option>
                                    </Select>
                                );
                            }
                        },
                        {
                            key: "sum",
                            title: 'Sum Assured',
                            dataIndex: 'sum'
                        },
                        {
                            key: "premium",
                            title: 'Premium',
                            dataIndex: 'premium'
                        },
                        {
                            key: "status",
                            title: 'status',
                            dataIndex: 'status'
                        },
                        {
                            key: "action",
                            title: 'Action',
                            render: (record) => {
                                return (
                                    <span><Button type="link" onClick={(e) => insuranceAction(record)}>{record.action}</Button></span>
                                );
                            }
                        }
                    ]
                } dataSource={data} pagination={false}/>


<div style={{ "display": newDependentForm, marginTop:"50px" }}>
                    <Form
                        onFinish={onFinishNewDependent}
                        labelCol={{
                            span: 4,
                        }}
                        wrapperCol={{
                            span: 14,
                        }}
                        layout="horizontal"
                        initialValues={{
                        }}
                    >

                        <Form.Item label="Dependent Name" required name="name">
                            <Input />
                        </Form.Item>
                        <Form.Item label="She/He is my" required name="relation">
                            <Select>
                                {/*<Select.Option value="Brother">Brother</Select.Option>
                                <Select.Option value="Father">Father</Select.Option>
                                <Select.Option value="Father-in-law">Father-in-law</Select.Option>
                                <Select.Option value="Mother">Mother</Select.Option>
                                <Select.Option value="Mother-in-law">Mother-in-law</Select.Option>
                                <Select.Option value="Spouse">Spouse</Select.Option>
                                <Select.Option value="Sister">Sister</Select.Option>*/}
                                <Select.Option value="Daughter">Daughter</Select.Option>
                                <Select.Option value="Son">Son</Select.Option>
                                <Select.Option value="Spouse">Spouse</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="Date of Birth" name="dob">
                            <DatePicker />
                        </Form.Item>
                        <Form.Item label="Age" name="age">
                            <InputNumber />
                        </Form.Item>
                        <Form.Item label="(-" >
                            <Button type="primary" htmlType="submit">Submit</Button>
                        </Form.Item>
                    </Form>
                    </div>

                <div style={{marginTop:"50px"}}>
                    <Space size={50}>
                        <Button type="primary" onClick={(e) => setNewDependentForm("block")}>Add New Dependent</Button>
                    </Space>
                </div>
                </div>
                </div>
        )
    }


    function renderAgreedMessage() {
        return (
            <div><center><h1>Thank you. Your premium details will be updated with the insurance company</h1></center></div>
        )

    }

};
