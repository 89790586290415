import logo from './logo.svg';
import './App.css';
import 'antd/dist/antd.css';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import {
    Layout,
    Menu,
    AutoComplete,
    Button,
    Cascader,
    Checkbox,
    Col,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    MenuProps,
    Table,
    Modal,
    Upload,
    message
} from 'antd';

import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import store from 'store2';
import { apiRoot, myBucket, AWS } from "./Config";
import * as qs from 'query-string';

const columns = [
    {
        key: "id",
        title: 'Name',
        dataIndex: 'id'
    },
    {
        key: "created_at",
        title: 'Created At',
        dataIndex: 'created_at'
    }
];


const { Header, Sider, Content } = Layout;
const { Option } = Select;
const residences = [
    {
        value: 'zhejiang',
        label: 'Zhejiang',
        children: [
            {
                value: 'hangzhou',
                label: 'Hangzhou',
                children: [
                    {
                        value: 'xihu',
                        label: 'West Lake',
                    },
                ],
            },
        ],
    },
    {
        value: 'jiangsu',
        label: 'Jiangsu',
        children: [
            {
                value: 'nanjing',
                label: 'Nanjing',
                children: [
                    {
                        value: 'zhonghuamen',
                        label: 'Zhong Hua Men',
                    },
                ],
            },
        ],
    },
];
const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};


export default function Freelancer(props) {
    const [form] = Form.useForm();
    const [collapsed, setCollapsed] = useState(false);
    const params = useParams();
    const [page, setPage] = useState([]);
    const [freelancers, setFreelancers] = useState([]);
    const [projects, setProjects] = useState([]);

    const [progress , setProgress] = useState(0);
    const [userScore , setUserScore] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);

    const urlParams = qs.parse(window.location.search);
    console.log("urlParams", urlParams);



    useEffect(() => {
        console.log(params);
        var freelanceAuth = store("freelance_auth");
        var headers = { ...freelanceAuth };

        console.log(freelanceAuth);

        if (params.page == "dashboard") {
            var apiUrl = `${apiRoot}submissions/freelancer/api/project/list?page=0&type=projects-evaluation`;
            console.log(headers);
            axios.get(apiUrl, { headers }).then(res => {
                console.log(res.data)
                setProjects(res.data);
            });
        }

        if (params.page == "score-card") {
            var apiUrl = `${apiRoot}submissions/freelancer/api/freelance-project/${urlParams["fuzzy-id"]}/score`;
            console.log(headers);
            axios.get(apiUrl, { headers }).then(res => {
                console.log(res.data)
                setUserScore(res.data);
            });
        }

        if (params.page == "active-projects") {
            var apiUrl = `${apiRoot}submissions/freelancer/api/project/list?page=0&type=projects-evaluation`;
            console.log(headers);
            axios.get(apiUrl, { headers }).then(res => {
                console.log(res.data)
                setProjects(res.data);
            });
        }
        if (params.page == "completed-projects") {
            var apiUrl = `${apiRoot}submissions/freelancer/api/project/list?page=0&type=completed-projects`;
            console.log(headers);
            axios.get(apiUrl, { headers }).then(res => {
                console.log(res.data)
                setProjects(res.data);
            });
        }
        if (params.page == "documents") {
            var apiUrl = `${apiRoot}submissions/freelancer/api/user/documents`;
            console.log(headers);
            axios.get(apiUrl, { headers }).then(res => {
                console.log(res.data)
                setFreelancers(res.data);
            });
        }
    }, []);



const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
}

const uploadFile = (file) => {
    console.log(file);
    var uploadParams = {Key: "/uploads/"+new Date().getTime()};
    uploadParams.Body  = file;
        myBucket.upload(uploadParams, function (err, data) {
            if (err) {
              console.log("Error", err);
            } if (data) {
              console.log("Upload Success", data.Location);
              var freelanceAuth = store("freelance_auth");
              var headers = {...freelanceAuth};
              axios.post(`${apiRoot}submissions/freelancer/api/upload/file`, {}, {headers}).then(res => {
                console.log(res.data)
            });
            }
          });
}


    const onFinish = (values) => {
        console.log('Received values of form: ', values);
    };

    const isApprovedFreelancer = () =>
    {
        var freelanceAuth = store("freelance_auth");
        var headers = { ...freelanceAuth };

        var apiUrl = `${apiRoot}submissions/freelancer/api/is-approved`;
        axios.get(apiUrl, { headers }).then(res => {
            console.log(res.data)
        });
    }

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="86">+86</Option>
                <Option value="87">+87</Option>
            </Select>
        </Form.Item>
    );
    const suffixSelector = (
        <Form.Item name="suffix" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="USD">$</Option>
                <Option value="CNY">¥</Option>
            </Select>
        </Form.Item>
    );
    const [autoCompleteResult, setAutoCompleteResult] = useState([]);

    const onWebsiteChange = (value) => {
        if (!value) {
            setAutoCompleteResult([]);
        } else {
            setAutoCompleteResult(['.com', '.org', '.net'].map((domain) => `${value}${domain}`));
        }
    };

    const websiteOptions = autoCompleteResult.map((website) => ({
        label: website,
        value: website,
    }));

    const items1: MenuProps['items'] = ['1', '2', '3'].map(key => ({
        key,
        label: `nav ${key}`,
    }));


    const menuClicked = (e) => {
        console.log(e);
        window.location.href = '/freelancer/' + e.key;
    };




    return (
        <Layout>
            <Header
                className="site-layout-background"
                style={{
                    padding: 0,
                }}
            >
                <div className="logo" />
                <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']} items={items1} />
            </Header>
            <Layout className="site-layout">
                <Sider trigger={null} collapsible collapsed={collapsed}>
                    <div className="logo" />
                    <Menu
                        theme="dark"
                        mode="inline"
                        defaultSelectedKeys={['1']}
                        onClick={(e) => menuClicked(e)}
                        items={[
                            {
                                key: 'dashboard',
                                icon: <UserOutlined />,
                                label: 'Dashboard',
                            },
                            {
                                key: 'active-projects',
                                icon: <UserOutlined />,
                                label: 'Active Projects',
                            },
                            {
                                key: 'completed-projects',
                                icon: <UserOutlined />,
                                label: 'Completed Projects',
                            },
                            {
                                key: 'documents',
                                icon: <VideoCameraOutlined />,
                                label: 'Documents',
                            }
                        ]}
                    />
                </Sider>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                    }}
                >

                    {(params.page == "dashboard") && (renderDashboard())}
                    {(params.page == "active-projects") && (renderActiveProjects())}
                    {(params.page == "completed-projects") && (renderCompletedProjects())}
                    {(params.page == "score-card") && (renderScoreCard())}
                    {(params.page == "documents") && (renderDocuments())}
                    {(params.page == "application") && (renderApplication())}

                </Content>
            </Layout>
        </Layout>
    );


    function renderDashboard() {

        isApprovedFreelancer();

        return (
            <div>
                
                <input type="file" onChange={handleFileInput}/>
                <button onClick={() => uploadFile(selectedFile)}>Upload NDA</button>
            </div>
        )
    }

    function renderApplication() {
        //window.location.href = "/freelancer/application";
    }

    function renderActiveProjects()
    {
        isApprovedFreelancer();
        return (
            <Table rowKey="id" columns={columns} dataSource={freelancers}/>
        )

    }




    function renderScoreCard()
    {
        isApprovedFreelancer();


        const columns = [
            {
                key: "name",
                title: 'Name',
                dataIndex: 'name'
            },
            {
                key: "name",
                title: 'Name',
                dataIndex: 'name'
            },
            {
                key: "user_rank",
                title: 'User Rank',
                dataIndex: 'user_rank'
            }
        ];


        return (
            <Table rowKey="id" columns={columns} dataSource={userScore}/>
        )
    }

    function renderCompletedProjects()
    {
        isApprovedFreelancer();


        const columns = [
            {
                key: "id",
                title: 'Name',
                dataIndex: 'id'
            },
            {
                key: "created_at",
                title: 'Created At',
                dataIndex: 'created_at'
            },
            {
                key: "render",
                title: 'Render',
                render: (value) => (
                  <Button onClick={(e) => window.location.href="/freelancer/score-card?fuzzy-id="+value.fuzzyId}>Score</Button>
                )
            }
        ];


        return (
            <Table rowKey="id" columns={columns} dataSource={projects}/>
        )
    }

    function renderDocuments()
    {
        isApprovedFreelancer();
        return (
            <Table rowKey="id" columns={columns} dataSource={freelancers}/>
        )
    }

};
