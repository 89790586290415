import React, {  useEffect } from 'react';

import './App.css';
import 'antd/dist/antd.css';
import  { whoAmI } from "./Config";
import axios from 'axios';
import store from 'store2';
import { apiRoot } from "./Config";

import { Button, Checkbox, Form, Input } from 'antd';
import { useHistory, useParams } from 'react-router-dom'

function Register() {

  const params = useParams();

  useEffect(() => {
    console.log(store.get("freelancer"));
    console.log(params);

    if(params.fuzzyid!==undefined)
    {
      axios.post(`${apiRoot}submissions/freelancer/public/verify-email/`+params.fuzzyid, {})
      .then(res => {
        if(res.data.status!=200) 
        {
          window.location.href = "/login/login";
          return;
        }
      }).catch(function (error) {
        console.log(error);
      });
    }

  },[]);

  const onFinish = (values) => {
    console.log('Success:', values);
    values["email"] = values["username"];
    values["url"] = window.location.origin;
    axios.post(`${apiRoot}submissions/freelancer/public/user/userregistration`, values)
    .then(res => {
      if(res.data.status!=200) 
      {
        window.location.href = "/";
        return;
      }
    }).catch(function (error) {
      console.log(error);
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  return (
    <div>
    <Form
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
        <Form.Item
        label="First Name"
        name="first_name"
        rules={[
          {
            required: true,
            message: 'Please input your username!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Last Name"
        name="last_name"
        rules={[
          {
            required: true,
            message: 'Please input your username!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Email"
        name="username"
        rules={[
          {
            required: true,
            message: 'Please input your username!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="remember"
        valuePropName="checked"
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
    </div>
  );
}

export default Register;
