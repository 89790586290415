import React, { useState, useEffect } from 'react';

import logo from './logo.svg';
import './Login.css';
import 'antd/dist/antd.css';
import axios from 'axios';
import * as qs from 'query-string';
import { apiRoot } from "./Config";

import { Button, Checkbox, Form, Input, message } from 'antd';
import store from 'store2';
import { useHistory, useParams } from 'react-router-dom'

var headers = {
  "Accept":"application/json",
  "token":"a5a217342d58275631093602c3d5b8a6-fd72887d8d03f98c85ebb57214d84842",
  "email":"arun@newgen.co",
}
var data = {};
var apiUrl = 'https://pubkittest.newgen.co/office/api/approvedqueries/fuzzyid/newgen-employees';

function Login() {
  const urlParams = qs.parse(window.location.search);
  const params = useParams();

  useEffect(() => {
    console.log(urlParams);

    console.log(store.get("freelancer"));

    if(params.message!==undefined && params.message=="login")
    {
      message.info('Please login with your credentials');
    }
  });

  const onFinish = (values) => {
    console.log('Success:', values);
    axios.post(`${apiRoot}submissions/authenticateparams`, values)
    .then(res => {
      if(res.data.status!=200) 
      {
        window.location.href = "/";
        return;
      }
      store("pubkit_auth", res.data["authToken"]);
      store("pubkit_user", res.data["user"]);
      var freelanceRole = [];
      for(var i=0; i<res.data["capabilities"].length; i++)
      {
        freelanceRole.push(res.data["capabilities"][i]["name"]);
      }
      store("pubkit_role", freelanceRole);

      var redirectPage = "/links";
      window.location.href = redirectPage;
    }).catch(function (error) {
      console.log(error);
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  return (
    
    <div className='login-page'>
      
    <Form
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Username"
        name="username"
        rules={[
          {
            required: true,
            message: 'Please input your username!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="remember"
        valuePropName="checked"
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Checkbox>Remember me</Checkbox>
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
    </div>
  );
}

export default Login;
