import logo from './logo.svg';
import './App.css';
import 'antd/dist/antd.css';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import {
    Layout,
    Menu,
    Radio,
    Typography,
    DatePicker,
    Switch,
    AutoComplete,
    Button,
    Cascader,
    Checkbox,
    Card,
    Col,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    MenuProps,
    Table,
    Modal,
    Upload,
    message,
    Space,
    Tabs
} from 'antd';

import AWS from 'aws-sdk'
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import store from 'store2';
import { apiRoot } from "./Config";
import MailHeader from './component/header'
import moment from 'moment'
import * as qs from 'query-string';

const { TabPane } = Tabs;
const { Search } = Input;

const urlParams = qs.parse(window.location.search);
console.log("urlParams", urlParams);

//https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/s3-example-creating-buckets.html
const credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: "us-east-1:d0488ef8-af13-4ba0-9c1c-aebd3215c422" });
const S3_BUCKET = 'newgen-ae-dev';
const REGION = 'us-east-1';

AWS.config.update({
    region: REGION,
    credentials: credentials
}
);

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})

AWS.config.credentials.refresh();
var headers = store.get("freelance_auth");


let restFul = axios.create({
    baseURL: apiRoot,
    timeout: 1000
  });

  restFul.interceptors.response.use((response) => response, (error) => {
    console.log(error);
    message.info(error.message);
    if(error.response.status==401)
    {
        window.location.href="/";
    }
  });



const { Header, Sider, Content } = Layout;
const { Option } = Select;

export default function AttendanceRegularization(props) {
    const [form] = Form.useForm();
    const [collapsed, setCollapsed] = useState(false);
    const params = useParams();
    const [page, setPage] = useState([]);
    const [data, setData] = useState([]);
    const [employeeData, setEmployeeData] = useState({});
    const [freelancers, setFreelancers] = useState([]);
    const [newDependentForm, setNewDependentForm] = useState("none");
    const [moreInfo, setMoreInfo] = useState("");
    const [requestedData, setRequestedData] = useState([]);
    const [selfAttendance, setSelfAttendance] = useState([]);
    
    
    const daysChanged = (e, record) =>
    {
        console.log(e.target.value, record);
        restFul.post(`/office/hr/api/leave/summary/update/`+record.fuzzyId, {"days": (e.target.value * 1)}, { headers }).then(res => {
            console.log(res.data);
            var attendance = [...selfAttendance];
            for(var i=0; i<attendance.length; i++)
            {
                var eachAttendance = {...attendance[i]};
                if(eachAttendance["fuzzyId"] == record.fuzzyId)
                {
                    eachAttendance["days"] = (e.target.value * 1);
                    attendance[i] = eachAttendance;
                }
            }
            setSelfAttendance(attendance);
        });
    }
    

    useEffect(() => {
        console.log(params);


        if(urlParams.page=="self")
        {
            const currentMonth = (moment(new Date()).format("MMM")).toUpperCase();
            restFul.get(`/office/hr/api/leave/summary/find/self/${moment(new Date()).format("YYYY")}/${currentMonth}`, { headers }).then(res => {
                setSelfAttendance(res.data.data);
            });
        }

        if(urlParams.page=="manager")
        {
            const currentMonth = (moment(new Date()).format("MMM")).toUpperCase();
            restFul.get(`/office/hr/api/leave/summary/find-by-manager/${moment(new Date()).format("YYYY")}/${currentMonth}`, { headers }).then(res => {
                setSelfAttendance(res.data.data);
            });
        }

        if(urlParams.page=="buhead")
        {
            const currentMonth = (moment(new Date()).format("MMM")).toUpperCase();
            restFul.get(`/office/hr/api/leave/summary/find-by-manager/${moment(new Date()).format("YYYY")}/${currentMonth}`, { headers }).then(res => {
                setSelfAttendance(res.data.data);
            });
        }
    }, []);


    return (
        <Layout>
            <Header
                className="site-layout-background"
                style={{
                    padding: 0,
                }}>
                <div className="logo" />
            </Header>
            <Layout className="site-layout">
                <Content
                    className="site-layout-background"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: '100vh',
                    }}>
                        
                    {(urlParams.page == "self") && (renderSelfScreen())}
                    {(urlParams.page == "manager") && (renderManagerScreen())}
                    {(urlParams.page == "buhead") && (renderBuScreen())}

                </Content>
            </Layout>
        </Layout>
    );



    function renderSelfScreen() {


        return (
            <div className='domainmail-continer'>
                <Card style={{marginTop:"50px"}}>
                <Typography.Title level={4}>Attendance Regularize</Typography.Title>
                <div>
                    <Tabs defaultActiveKey="self" onChange={(e) => window.location.href="/hr/payroll/attendance?page="+e}>
                        <TabPane tab="Self" key="self"></TabPane>
                        <TabPane tab="Reporting Manager" key="manager"></TabPane>
                        <TabPane tab="For BUs" key="buhead"></TabPane>
                    </Tabs>

                    <Table rowKey="id" columns={
                        [
                            {
                                title: 'Year',
                                dataIndex: 'year'
                            },
                            {
                                title: 'Month',
                                dataIndex: 'month'
                            },
                            {
                                title: 'Status',
                                dataIndex: 'status'
                            },
                            {
                                title: 'Leave From',
                                dataIndex: 'leaveFrom',
                                render: (record) => {
                                    return (
                                        <span>{moment(record).format("lll")}</span>
                                    );
                                }
                            },
                            {
                                title: 'Leave To',
                                dataIndex: 'leaveTo',
                                render: (record) => {
                                    return (
                                        <span>{moment(record).format("lll")}</span>
                                    );
                                }
                            },
                            {
                                title: 'Days',
                                dataIndex: 'days'
                            }
                        ]
                    } dataSource={selfAttendance} pagination={false} />
                </div>
                </Card>
            </div>
        )
    }



    function renderManagerScreen() {

        return (
            <div className='domainmail-continer'>
                <Card style={{marginTop:"50px"}}>
                <Typography.Title level={4}>Attendance Regularize</Typography.Title>
                <div>
                    <Tabs defaultActiveKey="manager" onChange={(e) => window.location.href="/hr/payroll/attendance?page="+e}>
                        <TabPane tab="Self" key="self"></TabPane>
                        <TabPane tab="Reporting Manager" key="manager"></TabPane>
                        <TabPane tab="For BUs" key="buhead"></TabPane>
                    </Tabs>

                    <Table rowKey="id" columns={
                        [
                            {
                                title: 'Year',
                                dataIndex: 'year'
                            },
                            {
                                title: 'Month',
                                dataIndex: 'month'
                            },
                            {
                                title: 'Status',
                                dataIndex: 'status'
                            },
                            {
                                title: 'Leave From',
                                dataIndex: 'leaveFrom',
                                render: (record) => {
                                    return (
                                        <span>{moment(record).format("lll")}</span>
                                    );
                                }
                            },
                            {
                                title: 'Leave To',
                                dataIndex: 'leaveTo',
                                render: (record) => {
                                    return (
                                        <span>{moment(record).format("lll")}</span>
                                    );
                                }
                            },
                            {
                                title: 'Days',
                                dataIndex: 'days'
                            },
                            {
                                title: 'Update',
                                render: (record) => {
                                    return (
                                        <InputNumber onPressEnter={(e) => daysChanged(e, record)}/>
                                    );
                                }
                            }
                        ]
                    } dataSource={selfAttendance} pagination={false} />
                </div>
                </Card>
            </div>

        )
    }

    function renderBuScreen() {


        return (
            <div className='domainmail-continer'>
                <Card style={{marginTop:"50px"}}>
                <Typography.Title level={4}>Attendance Regularize</Typography.Title>
                <div>
                    <Tabs defaultActiveKey="buhead" onChange={(e) => window.location.href="/hr/payroll/attendance?page="+e}>
                        <TabPane tab="Self" key="self"></TabPane>
                        <TabPane tab="Reporting Manager" key="manager"></TabPane>
                        <TabPane tab="For BUs" key="buhead"></TabPane>
                    </Tabs>

                    <Table rowKey="id" columns={
                        [
                            {
                                title: 'Year',
                                dataIndex: 'year'
                            },
                            {
                                title: 'Month',
                                dataIndex: 'month'
                            },
                            {
                                title: 'Status',
                                dataIndex: 'status'
                            },
                            {
                                title: 'Leave From',
                                dataIndex: 'leaveFrom',
                                render: (record) => {
                                    return (
                                        <span>{moment(record).format("lll")}</span>
                                    );
                                }
                            },
                            {
                                title: 'Leave To',
                                dataIndex: 'leaveTo',
                                render: (record) => {
                                    return (
                                        <span>{moment(record).format("lll")}</span>
                                    );
                                }
                            },
                            {
                                title: 'Days',
                                dataIndex: 'days'
                            },
                            {
                                title: 'Update',
                                render: (record) => {
                                    return (
                                        <InputNumber onPressEnter={(e) => daysChanged(e, record)}/>
                                    );
                                }
                            }
                        ]
                    } dataSource={selfAttendance} pagination={false} />
                </div>
                </Card>
            </div>
        )
    }

};
