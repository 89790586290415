import logo from './logo.svg';
import './App.css';
import 'antd/dist/antd.css';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import {
  Layout,
  Menu,
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Slider,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  MenuProps,
  Table,
  Modal
} from 'antd';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import store from 'store2';
import { apiRoot, myBucket, AWS } from "./Config";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as qs from 'query-string';

const { Header, Sider, Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;

var assignProjectProps = {};


export default function Manager(props) {
  const [form] = Form.useForm();
  const params = useParams();
  const [page, setPage] = useState([]);
  const [freelancers, setFreelancers] = useState([]);
  const [freelancerApplications, setFreelancerApplications] = useState([]);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedFreelancerIds, setSelectedFreelancerIds] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState();
  const [assignProjectModalVisible, setAssignProjectModalVisible] = useState(false);
  const [emailTemplateBody, setEmailTemplateBody] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [showProjectFileUpload, setShowProjectFileUpload] = useState("none");
  const [currentObject, setCurrentObject] = useState({});
  const [projectFreelancersModal, setProjectFreelancersModal] = useState(false);
  const [projectFreelancers, setProjectFreelancers] = useState([]);
  const [kpis, setKpis] = useState([]);
  const [applicationStatusModal, setApplicationStatusModal] = useState(false);
  const [projectTasks, setProjectTasks] = useState([]);
  const [projectTaskOptions, setProjectTaskOptions] = useState([]);

  
  const urlParams = qs.parse(window.location.search);



  const freelanceProjectManagerDecisions = (record, value) => {
    console.log(value, record);
    axios.put(`${apiRoot}submissions/freelancer/api/freelance-project/${record.fuzzyId}/decision/${value}`, {}, { headers }).then(res => {
      console.log(res.data);
    });
  }



  var freelanceAuth = store("freelance_auth");
  var headers = { ...freelanceAuth };


  useEffect(() => {
    console.log("props", props.props);
    console.log("params", params);
    console.log("urlParams", urlParams);
    console.log(freelanceAuth);

    //sara
    if (urlParams.status == "evaluation-form")
    {
      axios.get(`${apiRoot}submissions/freelancer/public/kpis?page=0&app=freelance`, { headers }).then(res => {
        console.log(res.data)
        setKpis(res.data);
      });
    }

    if (urlParams.status == "new-project") {
      var apiUrl = `${apiRoot}submissions/freelancer/api/project/list?page=0&type=new-projects`;
      console.log(headers);
      axios.get(apiUrl, { headers }).then(res => {
        console.log(res.data)
        setFreelancers(res.data);
      });
    }

    if (urlParams.status == "projects-assigned") {
      var apiUrl = `${apiRoot}submissions/freelancer/api/project/list?page=0&type=projects-assigned`;
      console.log(headers);
      axios.get(apiUrl, { headers }).then(res => {
        console.log(res.data)
        setFreelancers(res.data);
      });
    }
    

    if (urlParams.status == "projects-evaluation") {
      console.log(headers);
      axios.get(`${apiRoot}submissions/freelancer/api/project/freelancers?page=0&type=projects-evaluation`, { headers }).then(res => {
        console.log(res.data)
        setFreelancers(res.data);
      });
    }


    if (urlParams.status == "projects-completed") {
      var apiUrl = `${apiRoot}submissions/freelancer/api/project/list?page=0&type=projects-completed`;
      console.log(headers);
      axios.get(apiUrl, { headers }).then(res => {
        console.log(res.data)
        setFreelancers(res.data);
      });
    }

    if (props.props.page == "applications") {
      var apiUrl = `${apiRoot}submissions/freelancer/api/applications?page=0&status=`+urlParams["status"];
      console.log(headers);
      axios.get(apiUrl, { headers }).then(res => {
        console.log(res.data)
        setFreelancerApplications(res.data);
      });
    }


    if (props.props.page == "freelancers") {
      var apiUrl = `${apiRoot}submissions/freelancer/api/list?page=0`;
      console.log(headers);
      axios.get(apiUrl, { headers }).then(res => {
        console.log(res.data)
        setFreelancers(res.data);
      });

      axios.get(`${apiRoot}submissions/freelancer/api/email-templates`, { headers }).then(res => {
        console.log(res.data)
        setEmailTemplates(res.data);
      });
    }



  }, []);


  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  }

  const uploadFile = (file) => {
    console.log(file);
    var uploadParams = { Key: "/uploads/" + new Date().getTime() };
    uploadParams.Body = file;
    myBucket.upload(uploadParams, function (err, data) {
      if (err) {
        console.log("Error", err);
      } if (data) {
        console.log("Upload Success", data.Location);
        var freelanceAuth = store("freelance_auth");
        var headers = { ...freelanceAuth };
        data["projectId"] = currentObject["id"];
        data["filename"] = file.name;
        data["type"] = file.type;
        data["size"] = file.size;
        data["filepath"] = uploadParams["Key"];
        data["genre"] = "freelancer-project";
        axios.post(`${apiRoot}submissions/freelancer/api/upload/file`, data, { headers }).then(res => {
          console.log(res.data)
        });
      }
    });
  }



  const onFinish = (values) => {
    console.log('Received values of form: ', values);
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 70,
        }}
      >
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  );
  const suffixSelector = (
    <Form.Item name="suffix" noStyle>
      <Select
        style={{
          width: 70,
        }}
      >
        <Option value="USD">$</Option>
        <Option value="CNY">¥</Option>
      </Select>
    </Form.Item>
  );
  const [autoCompleteResult, setAutoCompleteResult] = useState([]);

  const onWebsiteChange = (value) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(['.com', '.org', '.net'].map((domain) => `${value}${domain}`));
    }
  };

  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));

  const items1: MenuProps['items'] = ['1', '2', '3'].map(key => ({
    key,
    label: `nav ${key}`,
  }));

  const menuClicked = (e) => {
    console.log(e);
    window.location.href = '/manager/' + e.key;
  };


  const assignProjectModalOk = (e) => {
    setAssignProjectModalVisible(false);
    assignProjectProps["freelancers"] = selectedFreelancerIds;
    assignProjectProps["projectId"] = (assignProjectProps["projectId"] * 1);
    console.log(assignProjectProps);
    axios.post(`${apiRoot}submissions/freelancer/api/project/send-invites`, assignProjectProps, { headers }).then(res => {
      console.log(res.data)
    });

  };

  const assignProjectModalCancel = () => {
    setAssignProjectModalVisible(false);
  };



  const searchProjects = (newValue) => {
    if (newValue) {
      fetchProjects("", newValue, setProjects);
    } else {
      setProjects([]);
    }
  };

  const searchFreelanceProjects = (newValue) => {
    if (newValue) {
      fetchProjects("new-projects,projects-assigned", newValue, setProjects);
    } else {
      setProjects([]);
    }
  };

  const changeKeyValue = (newValue, e) => {
    assignProjectProps[newValue] = e;
    console.log(assignProjectProps);
  };

  const onEmailTemplateSelect = (newValue, e) => {
    assignProjectProps[newValue] = e;
    const selectedTemplate = emailTemplates.filter(function (item) { return item.id == e; })
    console.log(selectedTemplate[0]["content"]);
    assignProjectProps["body"] = selectedTemplate[0]["content"];
    setEmailTemplateBody(selectedTemplate[0]["content"]);
    console.log(assignProjectProps);
  };




  const fetchProjects = (type, value, callback) => {
    axios.get(`${apiRoot}submissions/freelancer/api/project/search?q=` + value + "&type=" + type, { headers }).then(res => {
      console.log(res.data);
      var projectList = [];
      for (var i = 0; i < res.data.length; i++) {
        projectList.push({ "value": res.data[i]["id"], "text": res.data[i]["name"] });
      }
      callback(projectList);
    });
  }

  const projectOptions = projects.map((d) => <Option key={d.value}>{d.text}</Option>);
  const emailTemplateOptions = emailTemplates.map((d) => <Option key={d.id}>{d.name}</Option>);


  return (
    <Layout>
      <Header
        className="site-layout-background"
        style={{
          padding: 0,
        }}
      >
        <div className="logo" />
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']} items={items1} />
      </Header>
      <Layout className="site-layout">
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="logo" />
          <Menu
            theme="dark"
            mode="inline"
            onClick={(e) => menuClicked(e)}
            defaultSelectedKeys={['1']}
            items={[
              {
                key: 'freelancers?status=selected',
                icon: <UserOutlined />,
                label: 'Freelancers',
              },
              {
                key: 'applications?status=submitted',
                icon: <VideoCameraOutlined />,
                label: 'Applications',
              },
              {
                key: 'applications?status=in-testing',
                icon: <VideoCameraOutlined />,
                label: 'In Testing',
              },
              {
                key: 'projects?status=new-project',
                icon: <UploadOutlined />,
                label: 'New Project',
              },
              {
                key: 'projects?status=projects-assigned',
                icon: <UploadOutlined />,
                label: 'Projects',
              },
              {
                key: 'projects?status=projects-evaluation',
                icon: <UploadOutlined />,
                label: 'Evaluation Form',
              },
              {
                key: 'projects?status=projects-completed',
                icon: <UploadOutlined />,
                label: 'Projects Completed',
              },
            ]}
          />
        </Sider>
        <Content
          className="site-layout-background"
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
          }}
        >


          {(props.props.page == "projects" && urlParams.status == "projects-assigned") && (renderAssignedProjects())}
          {(props.props.page == "projects" && urlParams.status == "projects-evaluation") && (renderProjectsEvaluation())}
          {(props.props.page == "freelancers" && urlParams.status == "selected") && (renderFreelancers())}
          {(props.props.page == "applications") && (renderApplications())}
          {(props.props.page == "freelancers" && urlParams.status == "in-testing") && (renderFreelancers())}
          {(props.props.page == "freelancers" && urlParams.status == "evaluation-form") && (renderEvaluationForm())}
          {(props.props.page == "projects" && urlParams.status == "projects-completed") && (renderCompletedProjects())}
          {(props.props.page == "projects" && urlParams.status == "new-project") && (renderNewProjects())}







        </Content>

      </Layout>
    </Layout>
  );

  function renderFreelancers() {

    const columns = [
      {
        key: "full_name",
        title: 'Name',
        dataIndex: 'full_name'
      },
      {
        key: "score",
        title: 'Test Score',
        dataIndex: 'score'
      },
      {
        key: "score",
        title: 'Project Score (Avg)',
        dataIndex: 'score'
      },
      {
        key: "approved_at",
        title: 'Approved At',
        dataIndex: 'approved_at'
      }
    ];



    const onFreelancerSearch = (values) => {
      var apiUrl = `${apiRoot}submissions/freelancer/api/list?page=0`;
      console.log(headers);
      axios.get(apiUrl, { headers }).then(res => {
        console.log(res.data)
        setFreelancers(res.data);
      });
    }

    return (
      <div>
        <div>
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFreelancerSearch}
            autoComplete="off"
          >
            <Form.Item
              label="Skill"
              name="skill">
              <Select>
                <Option value="US">US</Option>
                <Option value="UK">UK</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Sub Skill"
              name="subskill">
              <Select>
                <Option value="US">US</Option>
                <Option value="UK">UK</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Division"
              name="division">
              <Select>
                <Option value="US">US</Option>
                <Option value="UK">UK</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="remember"
              valuePropName="checked"
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Checkbox>NDA Completed</Checkbox>
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Search
              </Button>
            </Form.Item>
          </Form>
        </div>
        
        <Table rowKey="id" rowSelection={{
          selectedFreelancerIds,
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedFreelancerIds(selectedRowKeys);
            console.log(selectedRowKeys);
          }
        }} columns={columns} dataSource={freelancers} />




        <Button type="primary" onClick={(e) => setAssignProjectModalVisible(true)}>Assign Freelancer</Button>


        <Modal
          visible={assignProjectModalVisible}
          title="Title"
          onOk={(e) => assignProjectModalOk(e)}
          onCancel={assignProjectModalCancel}
          footer={[
            <Button key="back" onClick={assignProjectModalCancel}>
              Return
            </Button>,
            <Button key="submit" type="primary" onClick={(e) => assignProjectModalOk(e)}>
              Submit
            </Button>
          ]}
        >


          <Select style={{ width: 120 }} onChange={(e) => onEmailTemplateSelect("emailTemplateId", e)}>
            {emailTemplateOptions}
          </Select>


          <Select
            showSearch
            value={selectedProject}
            placeholder={props.placeholder}
            style={{ width: 150 }}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={searchFreelanceProjects}
            onChange={(e) => changeKeyValue("projectId", e)}
            notFoundContent={null}
          >
            {projectOptions}
          </Select>


          <CKEditor
            editor={ClassicEditor}
            data={emailTemplateBody}
            onReady={editor => {
              console.log('Editor is ready to use!', editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              changeKeyValue("body", data);
              console.log({ event, editor, data });
            }}
            onBlur={(event, editor) => {
              console.log('Blur.', editor);
            }}
            onFocus={(event, editor) => {
              console.log('Focus.', editor);
            }}
          />


        </Modal>




      </div>
    )
  }




  function renderApplications() {

    const columns = [
      {
        key: "fullName",
        title: 'fullName',
        render: (record) => {
          return (
            <span><Button type="link" onClick={(e) => changeCurrentObject(record) }>{record.fullName}</Button></span>
          );
        }
      },
      {
        key: "createdAt",
        title: 'createdAt',
        dataIndex: "createdAt"
      }
    ];

    const changeCurrentObject = (record) =>
    {
      setCurrentObject(record);
      setApplicationStatusModal(true);
    }

    const changeApplicationStatus = (values) => {
      console.log(values);
      setApplicationStatusModal(false);
      axios.put(`${apiRoot}submissions/freelancer/api/application/${currentObject.fuzzyId}/update`, values, { headers }).then(res => {
        console.log(res.data);
      });
    }


    return (
      <div>
        <Table rowKey="fuzzyId" columns={columns} dataSource={freelancerApplications} />


        <Modal
          visible={applicationStatusModal}
          title="Title"
          footer={null}
          onCancel={(e) => setApplicationStatusModal(false)}>
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={changeApplicationStatus}
            autoComplete="off"
          >
            <Form.Item
              label="Status"
              name="status"
            >
              <Select style={{ width: 120 }} >
                <Select.Option value="submitted">SUBMITTED</Select.Option>
                <Select.Option value="in-testing">TESTING</Select.Option>
                <Select.Option value="approved">APPROVED</Select.Option>
              </Select>
            </Form.Item>

            {(urlParams.status=="in-testing") && (
              <Form.Item
              label="Score"
              name="score"
              >
                <InputNumber min={1} max={100} />
              </Form.Item>
            )}
            

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>

        </Modal>

      </div>






    )
  }


  function renderAssignedProjects() {


    const fetchProjectFreelancers = (record) => {
      console.log(record);
      axios.get(`${apiRoot}submissions/freelancer/api/project/${record.id}/freelancers`, { headers }).then(res => {
        console.log(res.data)
        setProjectFreelancers(res.data);
        setProjectFreelancersModal(true);
      });
    }


    const columns = [
      {
        key: "name",
        title: 'Name',
        dataIndex: 'name'
      },
      {
        key: "ititle",
        title: 'iTitle',
        dataIndex: 'ititle'
      },
      {
        key: "type",
        title: 'type',
        render: (record) => {
          return (
            <Select onChange={(e) => freelanceProjectManagerDecisions(record, e)} defaultValue={(record.type || "WAITING_FOR_DECISION")} style={{ width: 120 }} >
              <Select.Option value="projects-evaluation">EVALUATION</Select.Option>
              <Select.Option value="projects-assigned">ASSIGNED</Select.Option>
            </Select>
          )
        }
      },
      {
        key: "createdAt",
        title: 'createdAt',
        dataIndex: 'created_at'
      },
      {
        key: "view",
        title: 'Show',
        render: (record) => {
          return (
            <span><Button type="link" onClick={(e) => fetchProjectFreelancers(record)}>Info</Button></span>
          );
        }
      }
    ];

    const freelancerProjectManagerDecisions = (record, value) => {
      console.log(value, record);
      axios.put(`${apiRoot}submissions/freelancer/api/freelancer-project/${record.fuzzyId}/decision/${value}`, {}, { headers }).then(res => {
        console.log(res.data);
      });
    }



    return (
      <div>
        <Table rowKey="id" columns={columns} dataSource={freelancers} />


        <Modal
          visible={projectFreelancersModal}
          title="Title"
          onOk={(e) => setProjectFreelancersModal(false)}
          onCancel={(e) => setProjectFreelancersModal(false)}
          footer={[
            <Button key="back" onClick={(e) => setProjectFreelancersModal(false)}>
              Return
            </Button>
          ]}
        >

          <Table rowKey="id" columns={[{
            key: "fullName",
            title: 'fullName',
            dataIndex: 'fullName'
          },
          {
            key: "freelancerDecision",
            title: 'freelancerDecision',
            dataIndex: 'freelancerDecision'
          },
          {
            key: "managerDecision",
            title: 'managerDecision',
            render: (record) => {
              return (
                <Select onChange={(e) => freelancerProjectManagerDecisions(record, e)} defaultValue={(record.managerDecision || "WAITING_FOR_RESPONSE")} style={{ width: 120 }} >
                  <Select.Option value="SELECTED">SELECTED</Select.Option>
                  <Select.Option value="REJECTED">REJECTED</Select.Option>
                </Select>
              )
            }
          },
          {
            key: "createdAt",
            title: 'createdAt',
            dataIndex: 'createdAt'
          }]} dataSource={projectFreelancers} />


        </Modal>
      </div>
    )

  }





  function renderProjectsEvaluation() {

    const columns = [
      {
        key: "name",
        title: 'Name',
        dataIndex: 'name'
      },
      {
        key: "ititle",
        title: 'iTitle',
        dataIndex: 'ititle'
      },
      {
        key: "type",
        title: 'type',
        render: (record) => {
          return (
            <Select onChange={(e) => freelanceProjectManagerDecisions(record, e)} defaultValue={(record.type || "WAITING_FOR_DECISION")} style={{ width: 120 }} >
              <Select.Option value="projects-evaluation">EVALUATION</Select.Option>
              <Select.Option value="projects-completed">COMPLETED</Select.Option>
              <Select.Option value="projects-assigned">ASSIGNED</Select.Option>
            </Select>
          )
        }
      },
      {
        title: "Link",
        render: (record) => {
          return (
            <a onClick={(e) => window.location.href = `/manager/freelancers?status=evaluation-form&fuzzy-id=${record.fuzzyId}`} target="_blank">Evaluation Form</a>
          );
        }
      }
    ];


    const submitEvaluationForm = (values) =>
    {
      console.log(values);
    }

    return (
      <div>
        <Table rowKey="id" columns={columns} dataSource={freelancers} />

        <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={submitEvaluationForm}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: 'Please input your username!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="remember"
          valuePropName="checked"
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>

      </div>
    )


  }



  function renderCompletedProjects() {

    const freelancerProjectManagerDecisions = (record, value) => {
      console.log(value, record);
      axios.put(`${apiRoot}submissions/freelancer/api/freelancer-project/${record.fuzzyId}/decision/${value}`, {}, { headers }).then(res => {
        console.log(res.data);
      });
    }


    const columns = [
      {
        key: "name",
        title: 'Name',
        dataIndex: 'name'
      },
      {
        key: "type",
        title: 'type',
        render: (record) => {
          return (
            <Select onChange={(e) => freelanceProjectManagerDecisions(record, e)} defaultValue={(record.type || "WAITING_FOR_DECISION")} style={{ width: 120 }} >
              <Select.Option value="projects-evaluation">EVALUATION</Select.Option>
              <Select.Option value="projects-completed">COMPLETED</Select.Option>
              <Select.Option value="projects-assigned">ASSIGNED</Select.Option>
            </Select>
          )
        }
      },
      {
        key: "ititle",
        title: 'iTitle',
        dataIndex: 'ititle'
      }
    ];

    return (
      <Table rowKey="id" columns={columns} dataSource={freelancers} />
    )
  }





  function renderEvaluationForm() {

    const submitEvaluationForm = (values) =>
    {
      var kpis = [];
      for(var prop in values)
      {
        var kpiId = prop.replace("userRank_", "");
        if(kpiId==prop) continue;
        if(values[prop]===undefined) { values[prop] = 0; }
        kpiId = (kpiId * 1);
        var userScore = (values[prop] * 1);
        kpis.push({"id":kpiId, "score":userScore});
      }
      values["kpis"] = kpis;
      console.log(values);

      axios.post(`${apiRoot}submissions/freelancer/api/freelancer-project/${urlParams["fuzzy-id"]}/save/kpis`, values, { headers }).then(res => {
        console.log(res.data);
      });
      
    }

    return (
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={submitEvaluationForm}
        autoComplete="off"
      >

        {kpis.map((eachKpi) =>  
                <Form.Item name={"userRank_"+eachKpi.id} label={eachKpi.description}>
                <Select
                  style={{
                    width: 170,
                  }}
                  defaultValue="0"
                >
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="4">4</Option>
                  <Option value="5">5</Option>
                </Select>
              </Form.Item>
        )}


      <Form.Item name="remarks" label="Additional Remarks">
        <TextArea rows={4} />
      </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    )
  }






  function renderNewProjects() {

    const addFreelanceProject = (props) => {
      assignProjectProps["projectId"] = (assignProjectProps["projectId"] * 1);
      assignProjectProps["taskId"] = (assignProjectProps["taskId"] * 1);
      console.log(assignProjectProps);
      axios.post(`${apiRoot}submissions/freelancer/api/project/add`, assignProjectProps, { headers }).then(res => {
        console.log(res.data)
      });
    }


    const findProjectTasks = (projectId) =>
    {
      changeKeyValue("projectId", projectId);
      axios.get(`${apiRoot}submissions/freelancer/api/project/${projectId}/tasks`, { headers }).then(res => {
        console.log(res.data)
        setProjectTasks(res.data);
        var tempOptions = [];
        for(var i=0; i<res.data.length; i++)
        {
          tempOptions.push(<Option key={res.data[i]["id"]}>{res.data[i]["name"]}</Option>);
        }
        setProjectTaskOptions(tempOptions);
      });
    }

    
    const projectFileUpload = (record) => {
      console.log(record)
      setCurrentObject(record);
      setShowProjectFileUpload("block");
    }


    const columns = [
      {
        key: "name",
        title: 'Name',
        dataIndex: 'name'
      },
      {
        key: "ititle",
        title: 'iTitle',
        dataIndex: 'ititle'
      },
      {
        key: "render",
        title: 'Render',
        render: (value) => (
          <Button onClick={(e) => projectFileUpload(value)}>Upload</Button>
        )
      }
    ];

    return (
      <div>

        <Content
          className="site-layout-background"
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
          }}
        >

          <Select
            showSearch
            value={selectedProject}
            placeholder={props.placeholder}
            style={{ width: 150 }}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={searchProjects}
            onChange={(e) => findProjectTasks(e)}
            notFoundContent={null}
          >
            {projectOptions}
          </Select>

          <Select
            showSearch
            value={projectTasks}
            style={{ width: 150 }}
            showArrow={false}
            filterOption={false}
            onChange={(e) => changeKeyValue("taskId", e)}
          >
            {projectTaskOptions}
          </Select>

          <Button onClick={(e) => addFreelanceProject(e)}>Add</Button>
          


          <div style={{ "display": showProjectFileUpload }}>
            <input type="file" onChange={handleFileInput} />
            <button onClick={() => uploadFile(selectedFile)}>Upload NDA</button>
          </div>

        </Content>


        <Table rowKey="id" columns={columns} dataSource={freelancers} />




      </div>
    )
  }






};
