import './styles.css';


const MailHeader =()=>{
    return(
        <div className='header'>
        <div className='titel'>
           EMAIL ADDRESS MANAGEMENT
        </div>
        <div className='line'></div>
        <div className='body'>

        </div>
       </div>
    )
}

export default MailHeader;
