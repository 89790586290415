import './styles.css';

const GenIDHeader =()=>{

    return(

        <div className='header' >
            <center>
            <div className='titel' >

GENERATE ID CARD

</div>
            </center>
       

        <div className='line' style={{paddingLeft:"20px"}}>

            Please provide the necessary details to generate your ID card

        </div>

        <div className='body'>

        </div>

       </div>

    )

}

export default GenIDHeader;