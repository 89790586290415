import './App.css';
import 'antd/dist/antd.css';
import {PlusOutlined} from '@ant-design/icons';
import {Layout,Button,Form,Input,notification,Select,Upload,message,Tabs,Spin
} from 'antd';

import AWS from 'aws-sdk'
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {useParams } from 'react-router-dom'
import store from 'store2';
import { apiRoot } from "./Config";
import GenIDHeader from './component/GenIDHeader'
import * as qs from 'query-string';
const urlParams = qs.parse(window.location.search);
console.log("urlParams", urlParams);

const credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: "us-east-1:086c4450-fa44-46ac-ac22-bcba47a1007a" });
const S3_BUCKET = 'newgen-pk-prod';
const REGION = 'us-east-1';
AWS.config.update({
    region: REGION,
    credentials: credentials
}
);

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})

AWS.config.credentials.refresh();
let restFul = axios.create({
    baseURL: apiRoot,
    timeout: 1000
  });

  restFul.interceptors.response.use((response) => response, (error) => {
    console.log(error);
    message.info(error.message);
    if(error.response.status==401)
    {
        window.location.href="/";
    }
  });

const { Header,Content } = Layout;

const { Option } = Select;

const normFile=(e)=>{
    if(Array.isArray(e)){
        console.log(e)
        return e;
    }
    console.log(e)
    return e?.fileList;
}

export default function GenerateEmpCard(props) {
    const [form] = Form.useForm(); 
    const params = useParams();
    const [employeeImage, setEmployeeImage] = useState({}); 
    const [spinning, setSpinning] = React.useState(false);
    const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: 'Processing Form...',
      description:
        'Please wait while you form is being processed',
    });
  };
    let i =0;
    const onFinish=(values)=>{
      openNotificationWithIcon('info')
        setSpinning(true);
      console.log(values)
    
    let imageType = employeeImage.type.includes("png") ? ".png" : employeeImage.type.includes("jpeg") ? ".jpg" : "invalid";
    
    console.log("imgtype", imageType)
    if(imageType == "invalid") message.error("Please upload a valid passport-sized photo of yourself. Either in jpg or png format!");
      var uploadParams = {Key: `uploads/Employee_Id_Card/Employee_Photo/${values.empId}_${values.empFullName.replace(/\s/g, "")}${imageType}`, Body: employeeImage};

          console.log(uploadParams)

              myBucket.upload(uploadParams, function (err, data) {

                  if (err) {

                    console.log("Error", err);
                    message.error("Something went wrong, Please try again!")
                    return
                  } if (data) {

                    console.log("Upload Success", data);
                    let uploadData = values;
                    uploadData.empPhoto = "uploads/Employee_Id_Card/Employee_Photo/" + values.empId + "_" + values.empFullName.replace(/\s/g, "") + imageType;
                    uploadData.empAddress = ["Address: " + values.empAddress1, values.empAddress2, values.empAddress3, values.empAddress4]
                    delete uploadData["empAddress1"];
                    delete uploadData["empAddress2"];
                    delete uploadData["empAddress3"];
                    delete uploadData["empAddress4"];
                    console.log("to be uploaded",uploadData);
                    var freelanceAuth = store("freelance_auth");

                    console.log(freelanceAuth, "freelance")
                    
                    var headers = JSON.parse(localStorage.getItem("pubkit_auth"));
            
                    axios.post(`https://pubkit.newgen.co/office/api/employeeidcardgenerate`, uploadData, {headers: headers}).then(res => {

                      console.log(res.data)
                      
                  }).catch(err => {
                    console.log(err);
                    message.error("Something went wrong, Please try again!")
                    return
                  });
                  
                  message.success("Submitted successfully!")
                  setTimeout(function(){
                    window.location.reload();
                 }, 3000);  
                  }
                  else{
                    message.error("Something went wrong, Please try again!")
                    return
                  }

                });
              setTimeout(function(){
                setSpinning(false);
                   }, 3000); 
  }

    const uploadFile = (file) => {
        console.log(file, typeof file);
        setEmployeeImage(file);
    }

    useEffect(() => {
        console.log(params);
    }, []);

    var _URL = window.URL || window.webkitURL;

    const propss = {
      beforeUpload: (file) => {
        var img;
        img = new Image();
        const isPNG = (file.type === 'image/png' || file.type === "image/jpeg");
        var objectUrl = _URL.createObjectURL(file);
        img.onload = function () {
            const wid = this.width;
            const hgt  = this.height;
            let aspectRatio;
            if (wid > hgt) {
              aspectRatio = wid / hgt;
              } else {
              aspectRatio = hgt / wid;
              }
              console.log(wid, hgt, aspectRatio);
              if(aspectRatio >= 1.4 || aspectRatio <= 0.9 ) {
                message.error("This image is not a passport size format, please upload a passport-size photo");
                return false;
              }
            _URL.revokeObjectURL(objectUrl);
        }

        img.src = objectUrl;

        if (!isPNG) {
          message.error(`${file.name} is not an image file`);
        }
        return isPNG || Upload.LIST_IGNORE;
      },

      onChange: (info) => {
        console.log(info.fileList);
      },
    };

    return (
      <Layout>
        {contextHolder}
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
          }}
        >
          <div className="logo" />
        </Header>
        <Layout className="site-layout">
          <Content
            className="site-layout-background"
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "24px 16px",
              padding: 24,
              minHeight: "100vh",
            }}
          >
            <div className="domainmail-continer" style={{ width: "100%" }}>
              <GenIDHeader></GenIDHeader>

              <div className="requestform">
                <Form
                  form={form}
                  name="control-hooks"
                  onFinish={onFinish}
                  layout="vertical"
                  style={{
                    width: "600px",
                    padding: "30px",
                    background: "#e3e1e1",
                  }}
                >
                  <Form.Item
                    name="empFullName"
                    label="Your Full Name:"
                    rules={[
                      {
                        required: true,
                        max: 40,
                        message: "Full Name should be less than 40 characters",
                      },
                    ]}
                  >
                    <Input style={{ width: "50%" }} />
                  </Form.Item>

                  <Form.Item
                    name="empId"
                    label="Your Employee ID:"
                    rules={[
                      {
                        required: true,
                      }
                    ]}
                  >
                    <Input style={{ width: "50%" }} maxLength={8} />
                  </Form.Item>

                  <Form.Item
                    name="empEmail"
                    label="Your Email Address"
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                    ]}
                  >
                    <Input style={{ width: "50%" }} />
                  </Form.Item>

                  <Form.Item
                    name="empBloodGroup"
                    label="Your Blood Group:"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "50%" }}
                      placeholder="Select Your Blood Group"
                    >
                      <Option value="A+ve">A +ve</Option>
                      <Option value="A-ve">A -ve</Option>
                      <Option value="B+ve">B +ve</Option>
                      <Option value="B-ve">B -ve</Option>
                      <Option value="AB+ve">AB +ve</Option>
                      <Option value="AB-ve">AB -ve</Option>
                      <Option value="O+ve">O +ve</Option>
                      <Option value="O-ve">O -ve</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="empContact"
                    label="Your Phone Number:"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        message: "The value must be a number",
                        pattern: new RegExp(/^[0-9]+$/),
                      },
                    ]}
                  >
                    <Input style={{ width: "50%" }} />
                  </Form.Item>
                  <Form.Item
                    name="emergencyContact"
                    label="Your Emergency Contact:"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        message: "The value must be a number",
                        pattern: new RegExp(/^[0-9]+$/),
                      },
                    ]}
                  >
                    <Input style={{ width: "50%" }} />
                  </Form.Item>

                  <Form.Item
                    name="workLocation"
                    label="Work Location:"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input style={{ width: "50%" }} maxLength={20} />
                  </Form.Item>

                  <h6>Address:</h6>
                  <Form.Item
                    name="empAddress1"
                    rules={[
                      {
                        required: true,
                        message: "Address Line 1 cannot be empty"
                      },
                      {
                        max: 44,
                        message:
                          "Address Line should be less than 45 characters",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Address Line 1"
                      maxLength={44}
                      style={{
                        width: "90%",
                        
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="empAddress2"
                    rules={[
                      {
                        required: true,
                        message: "Address Line 2 cannot be empty"
                      },
                      {
                        max: 44,
                        message:
                          "Address Line should be less than 45 characters",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Address Line 2"
                      maxLength={44}
                      style={{
                        width: "90%",
                        
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="empAddress3"
                    rules={[
                      {
                        required: true,
                        message: "Address Line 3 cannot be empty"
                      },
                      {
                        max: 44,
                        message:
                          "Address Line should be less than 45 characters",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Address Line 3"
                      maxLength={44}
                      style={{
                        width: "90%",
                       
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="empAddress4"
                    rules={[
                      {
                        required: true,
                        message: "Address Line 4 cannot be empty"
                      },
                      {
                        max: 44,
                        message:
                          "Address Line should be less than 45 characters",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Address Line 4"
                      maxLength={44}
                      style={{
                        width: "90%",
                       
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Upload your Photo:"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                  >
                    <Upload
                      {...propss}
                      action={uploadFile}
                      listType="picture-card"
                      accept="image/png, image/jpeg"
                      maxCount={1}
                    >
                      <button
                        style={{
                          border: 0,

                          background: "non",
                        }}
                        type="button"
                      >
                        <PlusOutlined />

                        <div
                          style={{
                            marginTop: 8,
                          }}
                        >
                          Upload
                        </div>
                      </button>
                    </Upload>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ float: "right" }}
                    >
                      Submit
                    </Button>
                    <Spin spinning={spinning} style={{width: "100%", height: "100%"}} fullscreen />
                  </Form.Item>
                </Form>

                
              </div>
            </div>
          </Content>
        </Layout>
        
      </Layout>
    );

    

   

};